// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-posts-tsx": () => import("./../../../src/pages/BlogPosts.tsx" /* webpackChunkName: "component---src-pages-blog-posts-tsx" */),
  "component---src-pages-club-events-tsx": () => import("./../../../src/pages/clubEvents.tsx" /* webpackChunkName: "component---src-pages-club-events-tsx" */),
  "component---src-pages-club-rides-tsx": () => import("./../../../src/pages/clubRides.tsx" /* webpackChunkName: "component---src-pages-club-rides-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

